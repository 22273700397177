import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  HeadingProps,
  HStack,
  Link,
  SimpleGrid,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import isMobileDevice from 'ismobilejs';
import type { GetServerSideProps } from 'next';
import NextHead from 'next/head';
import NextImage from 'next/image';
import NextLink from 'next/link';
import React, { useMemo } from 'react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getPublicRuntimeConfig } from '../common/config';
import { ArticleStatus } from '../common/libs/article';
import { DiscloseScope } from '../common/libs/discloseScope';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import { createProfileImageUrl, createUserName } from '../common/libs/user';
import { NextPageWithLayout } from '../common/types';
import ArticlesGrid, { ArticleHeader } from '../components/ArticlesGrid';
import Carousel from '../components/Carousel';
import { IoChevronForward } from '../components/Icons';
import LayoutWithSidebar, {
  layoutWithSidebarLgPaddingTop,
} from '../components/LayoutWithSidebar';
import MobileAppDownloadBar from '../components/MobileAppDownloadBar';
import PostMotivateBanner from '../components/PostMotivateBanner';
import RecommendedArticlesByViewHistories from '../components/RecommendedArticlesByViewHistories';
import SlideRequests from '../components/SlideRequests';
import UserRecentlyViewedArticles, {
  UserRecentlyViewedArticle,
} from '../components/UserRecentlyViewedArticles';
import WeeklyRankingArticleSummary, {
  WeeklyRankingArticleHeader,
} from '../components/WeeklyRankingArticleSummary';
import * as date from '../server/libs/date';
import { getGaCid } from '../server/libs/googleAnalytics';
import { fetchAccountIntegrationProfiles } from '../server/libs/id';
import prisma from '../server/libs/prisma';
import withAuth from '../server/libs/withAuth';

const BADGE_SHOW_HOUR = 48;
const MAX_POPULAR_TAGS_COUNT = 12;
const MIN_VIEW_HISTORY_COUNT = 6;
/**
 * 2023.3.23 新着スライドがトップページに表示されていないことへの対応
 * @todo 2023.3.27に見直し（9に戻す）
 */
const MAX_NEW_ARTICLES_COUNT = 12;

type Tag = {
  id: number;
  name: string;
};

type Author = {
  userId: number;
  userName: string | null;
  lastName: string | null;
  firstName: string | null;
  hospital: string | null;
  departmentName: string;
  hasProfileImage: boolean;
  idBaseProfileUrl: string | null;
  updateDateEpochMilliseconds: number;
};

type PageContext = {
  featuredArticles: ArticleHeader[];
  newArticles: ArticleHeader[];
  weeklyRankingArticles: WeeklyRankingArticleHeader[];
  popularTags: Tag[];
  popularAuthors: Author[];
  userViewedArticleCount: number | null;
  userRecentlyViewedArticles: UserRecentlyViewedArticle[] | null;
  isMobile: boolean;
};

function PageHead() {
  const { slideWebBaseUrl } = getPublicRuntimeConfig();
  return (
    <NextHead>
      <link rel="canonical" href={slideWebBaseUrl} />

      <meta property="og:url" content={slideWebBaseUrl} key="og:url" />
    </NextHead>
  );
}

function SectionHeading({ children, ...rest }: HeadingProps) {
  return (
    <Heading {...rest} fontSize="2xl">
      {children}
    </Heading>
  );
}

/**
 * 閲覧履歴
 *
 * 現在不使用。将来的に復活させることを考慮して残しておく。
 */
function UserRecentlyViewedArticlesSection({
  viewedArticleCount,
  viewedArticles,
}: {
  viewedArticleCount: number | null;
  viewedArticles: UserRecentlyViewedArticle[] | null;
}) {
  if (!viewedArticleCount || !viewedArticles || viewedArticles.length === 0) {
    return null;
  }

  const onClickMoreHistory = () => {
    sendAnalyticsEvent('view_history', 'click', 'from_top');
  };

  return (
    <Box w="full">
      {/* HACK: 画面横幅いっぱいまで背景色変更するためのCSSテクニック */}
      <Box w="100vw" m="0 calc(50% - 50vw)" bg="blackAlpha.50" py={4}>
        <Container>
          <HStack wrap="wrap" spacing={0} justify="space-between">
            <Text fontWeight="bold" mb={2}>
              閲覧履歴
            </Text>
            {viewedArticles && viewedArticleCount > MIN_VIEW_HISTORY_COUNT && (
              <Box>
                <NextLink href={'/mypage/view-history'} passHref>
                  <Link
                    display="inline-block"
                    color="inherit"
                    _hover={{
                      textDecoration: 'none',
                    }}
                    onClick={onClickMoreHistory}
                  >
                    <HStack pr={0} mb={2}>
                      <Text fontSize="sm" fontWeight="bold">
                        すべて見る
                      </Text>
                      <ChevronRightIcon color="brand.500" w={6} h={6} />
                    </HStack>
                  </Link>
                </NextLink>
              </Box>
            )}
          </HStack>
          <UserRecentlyViewedArticles
            articles={viewedArticles}
            onClickArticle={() => {
              sendAnalyticsEvent(
                'slide',
                'click',
                'user_recently_viewed_from_top',
              );
            }}
          />
        </Container>
      </Box>
    </Box>
  );
}

function FeaturedArticles({
  articles,
  isMobile,
}: {
  articles: ArticleHeader[];
  isMobile: boolean;
}) {
  if (articles.length === 0) {
    return null;
  }
  return (
    <Box>
      <SectionHeading mb={2}>注目のスライド</SectionHeading>
      <ArticlesGrid
        articles={articles}
        gaEventLabel={'featured_from_top'}
        additionalGaEvent={{
          category: 'featured',
          action: 'click',
          label: null, // ArticlesGrid内でarticle_idを設定する
        }}
        isMobile={isMobile}
      />
    </Box>
  );
}

function WeeklyRankingArticles({
  articles,
}: {
  articles: WeeklyRankingArticleHeader[];
}) {
  if (articles.length === 0) {
    return null;
  }

  const bgGradient = [
    'linear(to-r, yellow.400, yellow.500)',
    'linear(to-r, gray.300, gray.400)',
    'linear(to-r, yellow.700, yellow.800)',
    'gray',
  ];

  return (
    <Box>
      <SectionHeading mb={2}>週間スライドランキング</SectionHeading>
      <Box>
        {articles.map((article, i) => (
          <Box pos="relative" key={article.articleId} mb={4}>
            <WeeklyRankingArticleSummary
              key={article.articleId}
              {...article}
              gaEventLabel={`weekly_ranking_${i + 1}_from_top`}
              updateDate={article.updateDate}
              p={2}
            />
            <Center
              pos="absolute"
              top={0}
              left={0}
              bg="gray.500"
              bgGradient={bgGradient[i]}
              borderRadius="full"
              color="white"
              fontSize="xs"
              w={6}
              h={6}
            >
              {i + 1}
            </Center>
          </Box>
        ))}
        <NextLink href={'/weekly-ranking-articles'} passHref>
          <Button
            as="a"
            w="100%"
            bg="white.500"
            color="brand.500"
            height={4}
            fontSize={14}
            _hover={{
              bg: 'white.500',
              textDecoration: 'underline',
            }}
            onClick={() =>
              sendAnalyticsEvent(
                'weekly_ranking',
                'click',
                'weekly_ranking_from_top',
              )
            }
          >
            すべて見る
          </Button>
        </NextLink>
      </Box>
    </Box>
  );
}

function NewArticles({
  articles,
  isMobile,
}: {
  articles: ArticleHeader[];
  isMobile: boolean;
}) {
  if (articles.length === 0) {
    return null;
  }

  const articlesWithBadgeType: ArticleHeader[] = articles.map((article) => {
    const createDate = new Date(article.createDate);
    const updateDate = new Date(article.updateDate);
    if (
      !date.isAfterDiffHourSinceTargetDateToCurrentDate(
        BADGE_SHOW_HOUR,
        createDate,
      )
    ) {
      return {
        ...article,
        badgeType: 'new',
      };
    } else if (
      !date.isAfterDiffHourSinceTargetDateToCurrentDate(
        BADGE_SHOW_HOUR,
        updateDate,
      )
    ) {
      return {
        ...article,
        badgeType: 'updated',
      };
    }
    return article;
  });

  return (
    <Box>
      <SectionHeading mb={2}>新着</SectionHeading>
      <ArticlesGrid
        articles={articlesWithBadgeType}
        gaEventLabel={'new_from_top'}
        isMobile={isMobile}
      />
      <NextLink href={'/articles'} passHref>
        <Button
          as="a"
          w="100%"
          mt={4}
          bg="white.500"
          color="brand.500"
          height={4}
          fontSize={14}
          _hover={{
            bg: 'white.500',
            textDecoration: 'underline',
          }}
          onClick={() => sendAnalyticsEvent('slides', 'click', 'new_from_top')}
        >
          すべて見る
        </Button>
      </NextLink>
    </Box>
  );
}

function CareerTag() {
  const onClickTag = () => {
    sendAnalyticsEvent('slide', 'index', 'career_tag');
  };
  const p = new URLSearchParams();
  p.set('q', 'キャリア');

  return (
    <NextLink href={`/search?${p.toString()}`} passHref>
      <Link
        display="inline-block"
        color="inherit"
        _hover={{
          textDecoration: 'none',
        }}
        onClick={onClickTag}
      >
        <Text
          px={3}
          py={1.5}
          fontSize="sm"
          fontWeight="bold"
          color="white"
          bg="brand.500"
          borderRadius="full"
        >{`#キャリア`}</Text>
      </Link>
    </NextLink>
  );
}

function PopularTags({ tags }: { tags: Tag[] }) {
  const onClickTag = () => {
    sendAnalyticsEvent('slide', 'index', 'frequently_used_tag');
  };
  return (
    <Swiper
      modules={[Navigation]}
      style={{
        width: '100%',
      }}
      navigation
      lazy={false}
      className="swiper-container-tags"
      slidesPerView={'auto'}
      spaceBetween={10}
    >
      {tags.map((tag) => (
        <SwiperSlide key={tag.name} style={{ width: 'fit-content' }}>
          <NextLink href={`/tagsearch/${tag.id}`} passHref>
            <Link
              display="inline-block"
              color="inherit"
              _hover={{
                textDecoration: 'none',
              }}
              onClick={onClickTag}
            >
              <Text
                px={3}
                py={1.5}
                fontSize="sm"
                fontWeight="bold"
                color="white"
                bg="brand.500"
                borderRadius="full"
              >{`#${tag.name}`}</Text>
            </Link>
          </NextLink>
        </SwiperSlide>
      ))}
      <SwiperSlide style={{ width: 'fit-content' }}>
        <CareerTag />
      </SwiperSlide>
    </Swiper>
  );
}

function PopularAuthors({ authors }: { authors: Author[] }) {
  const hoverBgColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.100');
  const chevronColor = useColorModeValue('brand.500', 'brand.200');

  const authorsForView = useMemo(
    () =>
      authors.map((author) => {
        let profileImageUrl: string | undefined = undefined;
        if (author.hasProfileImage) {
          profileImageUrl = createProfileImageUrl(author.userId, {
            version: author.updateDateEpochMilliseconds,
          });
          if (author.idBaseProfileUrl) {
            profileImageUrl += `${
              author.updateDateEpochMilliseconds ? '&' : '?'
            }idurl=${encodeURIComponent(author.idBaseProfileUrl)}`;
          }
        }
        return {
          ...author,
          profileUrl: `/profile/${author.userId}`,
          profileImageUrl,
          fullName: createUserName(author),
          affiliation: (() => {
            if (author.hospital && author.departmentName) {
              return `${author.hospital}/${author.departmentName}`;
            } else if (author.hospital) {
              return author.hospital;
            } else if (author.departmentName) {
              return author.departmentName;
            } else {
              return undefined;
            }
          })(),
        };
      }),
    [authors],
  );

  if (authors.length === 0) {
    return null;
  }

  return (
    <Box>
      <SectionHeading mb={2}>今注目の投稿者</SectionHeading>
      <SimpleGrid columns={[1, 2, 3]} spacing={0} w="full">
        {authorsForView.map((author) => (
          <NextLink href={author.profileUrl} passHref key={author.userId}>
            <Link
              display="inline-block"
              p={2}
              borderRadius="lg"
              color="inherit"
              _hover={{
                textDecoration: 'none',
                bgColor: hoverBgColor,
              }}
              onClick={() =>
                sendAnalyticsEvent(
                  'profile',
                  'click',
                  'popular_author_from_top',
                )
              }
            >
              <HStack>
                <Box
                  pos="relative"
                  w="60px"
                  h="60px"
                  borderRadius="full"
                  overflow="hidden"
                  flexShrink={0}
                >
                  <NextImage
                    src={
                      author.profileImageUrl ||
                      '/images/avatar_default_gray.png'
                    }
                    alt={author.fullName}
                    layout="fill"
                    objectFit="cover"
                  />
                </Box>
                <Box>
                  <Text fontSize="md" noOfLines={1} wordBreak="break-all">
                    {author.fullName}
                  </Text>
                  <Text fontSize="sm" color="gray" noOfLines={1} mt={1}>
                    {author.affiliation}
                  </Text>
                </Box>
                <Spacer />
                <Box color={chevronColor}>
                  <IoChevronForward fontSize={30} />
                </Box>
              </HStack>
            </Link>
          </NextLink>
        ))}
      </SimpleGrid>
    </Box>
  );
}

const HomePage: NextPageWithLayout<PageContext> = ({
  featuredArticles,
  newArticles,
  weeklyRankingArticles,
  popularTags,
  popularAuthors,
  userViewedArticleCount,
  userRecentlyViewedArticles,
  isMobile,
}) => {
  return (
    <>
      <PageHead />
      <Carousel />
      <VStack spacing={12} align="stretch">
        <PopularTags tags={popularTags} />
        <NewArticles articles={newArticles} isMobile={isMobile} />
        <FeaturedArticles articles={featuredArticles} isMobile={isMobile} />
        <RecommendedArticlesByViewHistories />
        <WeeklyRankingArticles articles={weeklyRankingArticles} />
        <PopularAuthors authors={popularAuthors} />
        <PostMotivateBanner gaEventLabel="from_top" />
        <MobileAppDownloadBar />
      </VStack>
    </>
  );
};

const sidebarContent = (
  <Box
    w={{
      base: '100%',
      lg: '320px',
      xl: '360px',
    }}
    display={{
      // リクエストスライドページ追加時点ではPC画面以外サイドバー非表示
      base: 'none',
      lg: 'block',
    }}
  >
    <SlideRequests marginTop={layoutWithSidebarLgPaddingTop} />
  </Box>
);

HomePage.getLayout = function getLayout(page: React.ReactNode) {
  return (
    <LayoutWithSidebar sidebarContent={sidebarContent}>
      {page}
    </LayoutWithSidebar>
  );
};

export default HomePage;

/**
 * Featuredコンテンツ一覧を取得
 */
async function findFeaturedArticles() {
  return await prisma.article_header
    .findMany({
      select: {
        article_id: true,
        name: true,
        status: true,
        top_img_m: true,
        session_count: true,
        like_count: true,
        disclose_scope: true,
        create_date: true,
        update_date: true,
        user_article_header_author_idTouser: true,
        article_tag_connection: {
          select: {
            tag: true,
          },
        },
        article_page: {
          select: {
            like_count: true,
          },
        },
        article_comments: {
          select: {
            id: true,
          },
        },
      },
      where: {
        AND: {
          featured: {
            not: 0,
          },
          status: 'DONE',
          hidden: false,
          is_deleted: false,
        },
      },
      orderBy: {
        featured: 'asc',
      },
    })
    .then((articles) =>
      articles.map((article) => ({
        ...article,
        commentCount: article.article_comments.length,
      })),
    );
}

/**
 * 新着コンテンツ一覧を取得
 */
async function findNewArticles() {
  return await prisma.article_header
    .findMany({
      take: MAX_NEW_ARTICLES_COUNT,
      select: {
        article_id: true,
        name: true,
        status: true,
        top_img_m: true,
        session_count: true,
        like_count: true,
        disclose_scope: true,
        create_date: true,
        update_date: true,
        user_article_header_author_idTouser: true,
        article_tag_connection: {
          select: {
            tag: true,
          },
        },
        article_page: {
          select: {
            like_count: true,
          },
        },
        article_comments: {
          select: {
            id: true,
          },
        },
      },
      where: {
        AND: {
          status: 'DONE',
          hidden: false,
          is_deleted: false,
        },
      },
      orderBy: {
        update_date: 'desc',
      },
    })
    .then((articles) =>
      articles.map((article) => ({
        ...article,
        commentCount: article.article_comments.length,
      })),
    );
}

/**
 * 週間スライドランキングのコンテンツ一覧を取得
 */
async function findWeeklyRankingArticles() {
  const lastMonday = date.getMondayOfLastWeekJst(new Date());

  return await prisma.article_page_view_weekly_ranking
    .findMany({
      take: 4,
      where: {
        start_date: lastMonday,
        article_header: {
          status: 'DONE',
          hidden: false,
          is_deleted: false,
        },
      },
      orderBy: {
        count: 'desc',
      },
      include: {
        article_header: {
          select: {
            article_id: true,
            name: true,
            description: true,
            status: true,
            top_img_m: true,
            session_count: true,
            like_count: true,
            disclose_scope: true,
            create_date: true,
            update_date: true,
            user_article_header_author_idTouser: true,
            article_tag_connection: {
              select: {
                tag: true,
              },
            },
            article_page: {
              select: {
                like_count: true,
              },
            },
            article_comments: {
              select: {
                id: true,
              },
            },
          },
        },
      },
    })
    .then((rankings) =>
      rankings.map((ranking) => ({
        ...ranking.article_header,
        commentCount: ranking.article_header.article_comments.length,
      })),
    );
}

/**
 * 人気のタグ一覧を取得
 *
 * タグに紐づいたスライド数の多い順に {@link MAX_POPULAR_TAGS_COUNT} 個のタグを返却する
 * 「結節性硬化症」タグを4番目に固定で挿入する
 */
async function findPopularTags(): Promise<Tag[]> {
  const tuberousSclerosisTagId = 3439; // [結節性硬化症]タグ
  const insertIndex = 3;

  const tuberousSclerosisTagSearchResult = await prisma.tag.findUnique({
    select: {
      tag_id: true,
      tag_name: true,
    },
    where: {
      tag_id: tuberousSclerosisTagId,
    },
  });
  const tuberousSclerosisTag = tuberousSclerosisTagSearchResult
    ? {
        id: tuberousSclerosisTagSearchResult.tag_id,
        name: tuberousSclerosisTagSearchResult.tag_name!,
      }
    : null;

  const searchResult = await prisma.tag
    .findMany({
      take: MAX_POPULAR_TAGS_COUNT,
      select: {
        tag_id: true,
        tag_name: true,
      },
      where: {
        tag_name: {
          not: null,
        },
        tag_id: {
          not: tuberousSclerosisTagId,
        },
      },
      orderBy: {
        article_tag_connection: {
          _count: 'desc',
        },
      },
    })
    .then((tags) =>
      tags.map((tag) => ({
        id: tag.tag_id,
        name: tag.tag_name!,
      })),
    );
  if (tuberousSclerosisTag) {
    if (searchResult.length <= insertIndex) {
      searchResult.push(tuberousSclerosisTag);
    } else {
      searchResult.splice(insertIndex, 0, tuberousSclerosisTag);
    }
  }
  return searchResult;
}

/**
 * 今注目の投稿者一覧を取得
 *
 * @todo 1回の集計結果のレコードが9レコード未満の場合同じ投稿者が表示される問題を解決する
 */
async function findPopularAuthors() {
  const departments = await prisma.departments.findMany({
    select: {
      id: true,
      department_name_ja: true,
    },
  });

  const summary = await prisma.author_contents_view_histories_summary.findFirst(
    {
      select: { end_date: true },
      orderBy: [
        {
          end_date: 'desc',
        },
      ],
    },
  );

  const findHospital = async (hospitalId: number) => {
    const hospital = await prisma.hospitals.findUnique({
      where: {
        id: hospitalId,
      },
      select: {
        name: true,
      },
    });
    return hospital ? hospital.name : null;
  };

  const findHospitalByDiscloseId = async (discloseId: string) => {
    const hospital = await prisma.hospitals.findUnique({
      where: {
        disclose_id: discloseId,
      },
      select: {
        name: true,
      },
    });
    return hospital ? hospital.name : null;
  };

  if (!summary) {
    return [];
  }

  return await prisma.author_contents_view_histories_summary
    .findMany({
      include: {
        user: {
          select: {
            user_id: true,
            last_name: true,
            first_name: true,
            hospital_id: true,
            hospital: true,
            department_id: true,
            profile_photo_s3_key: true,
            update_date: true,
            disclose_id: true,
          },
        },
      },
      where: {
        user: {
          is_deleted: false,
        },
        end_date: summary.end_date,
      },
      take: 9,
      orderBy: [
        {
          end_date: 'desc',
        },
        {
          view_count: 'desc',
        },
      ],
    })
    .then((summaries) => summaries.map((summary) => summary.user))
    .then(async (users) => {
      // 投稿者がアカウント統合済みであればID基盤側のプロフィール情報を表示させる
      const discloseIds = users
        .map((v) => v.disclose_id ?? '')
        .filter((v) => !!v);
      const idBaseProfileMap = await fetchAccountIntegrationProfiles(
        discloseIds,
      );

      return await Promise.all(
        users.map(async (user) => {
          // アカウント統合済みの場合は、ID基盤側のプロフィール情報を表示させる
          const idBaseProfile = idBaseProfileMap[user.disclose_id ?? ''];

          // アカウント統合完了時はID基盤側のプロフィールに設定されたhospital_idを用いるが
          // hospitalsのテーブルマスタ情報はSlideとID基盤（QA）で等しいため
          // Slide DBから取得する
          let hospitalName: string | null = null;
          if (idBaseProfile?.hospitalId) {
            hospitalName = await findHospitalByDiscloseId(
              idBaseProfile.hospitalId,
            );
          }
          hospitalName ??= idBaseProfile?.hospitalName ?? null;
          // ID基盤側のIDでレコード取得できなかった場合はSlide側のIDでフォールバックさせる
          if (!hospitalName && user.hospital_id) {
            hospitalName = await findHospital(user.hospital_id);
          }
          hospitalName ??= user.hospital;
          // アカウント統合完了時はID基盤側のプロフィールに設定されたdepartment_idを用いるが
          // departmentsのテーブルマスタ情報はSlideとID基盤（QA）で等しいため
          // Slide DBから取得する
          let departmentName: string | undefined = undefined;
          if (idBaseProfile?.departmentId) {
            const departmentId = idBaseProfile.departmentId as number;
            departmentName = departments.find(
              (v) => v.id === departmentId,
            )?.department_name_ja;
          }
          if (!departmentName) {
            departmentName = departments.find(
              (v) => v.id === user.department_id,
            )?.department_name_ja;
          }

          const profilePhotoKey =
            idBaseProfile?.profilePhotoUrl ?? user.profile_photo_s3_key;
          return {
            userId: user.user_id,
            userName: idBaseProfile?.userName ?? null,
            // userNameが設定されている場合はfirstName, lastNameはフロントに返さないようにする
            lastName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.lastName ?? user.last_name,
            firstName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.firstName ?? user.first_name,
            hospital: hospitalName,
            departmentName: departmentName ?? '',
            hasProfileImage: !!profilePhotoKey,
            idBaseProfileUrl: idBaseProfile?.profilePhotoUrl ?? null,
            updateDateEpochMilliseconds: user.update_date.getTime(),
          };
        }),
      );
    });
}

async function countUserRecentlyViewedArticles(
  userId: number,
): Promise<number> {
  return await prisma.user_contents_view_histories.count({
    where: {
      user_id: userId,
      article_header: {
        status: 'DONE',
        hidden: false,
        is_deleted: false,
      },
    },
  });
}

async function findUserRecentlyViewedArticles(
  userId: number,
): Promise<UserRecentlyViewedArticle[]> {
  return await prisma.user_contents_view_histories
    .findMany({
      where: {
        user_id: userId,
        article_header: {
          status: 'DONE',
          hidden: false,
          is_deleted: false,
        },
      },
      select: {
        article_header: {
          select: {
            article_id: true,
            name: true,
            top_img_m: true,
            update_date: true,
          },
        },
      },
      orderBy: {
        update_date: 'desc',
      },
      take: MIN_VIEW_HISTORY_COUNT,
    })
    .then((histories) =>
      histories.map((history) => ({
        articleId: history.article_header.article_id,
        name: history.article_header.name,
        topImageM: history.article_header.top_img_m,
        updateDateEpochMilliseconds:
          history.article_header.update_date.getTime(),
      })),
    );
}

/**
 * user_access_historiesテーブルに閲覧履歴を記録
 */
async function createUserAccessHistory(
  userId?: number,
  userAgent?: string,
  gaCid?: string,
) {
  await prisma.user_access_histories.create({
    data: {
      user_id: userId,
      ga_cid: gaCid,
      user_agent: userAgent,
      operation: 'index',
    },
  });
}

export const getServerSideProps: GetServerSideProps<PageContext> = withAuth(
  async (context) => {
    const featuredArticlesPromise = findFeaturedArticles();
    const newArticlesPromise = findNewArticles();
    const weeklyRankingArticlesPromise = findWeeklyRankingArticles();

    const [
      featuredArticlesFromDb,
      newArticlesFromDb,
      weeklyRankingArticlesFromDb,
    ] = await Promise.all([
      featuredArticlesPromise,
      newArticlesPromise,
      weeklyRankingArticlesPromise,
    ]);

    // 投稿者がアカウント統合済みであればID基盤側のプロフィール情報を表示させる
    let discloseIds = featuredArticlesFromDb
      .map((v) => v.user_article_header_author_idTouser.disclose_id ?? '')
      .filter((v) => !!v);
    discloseIds = discloseIds.concat(
      newArticlesFromDb
        .map((v) => v.user_article_header_author_idTouser.disclose_id ?? '')
        .filter((v) => !!v),
    );
    discloseIds = discloseIds.concat(
      weeklyRankingArticlesFromDb
        .map((v) => v.user_article_header_author_idTouser.disclose_id ?? '')
        .filter((v) => !!v),
    );
    const idBaseProfileMap = await fetchAccountIntegrationProfiles(discloseIds);

    const [featuredArticles, newArticles] = [
      featuredArticlesFromDb,
      newArticlesFromDb,
    ].map((articles) =>
      articles.map((article) => {
        const author = article.user_article_header_author_idTouser;
        const idBaseProfile = idBaseProfileMap[author.disclose_id ?? ''];
        return {
          articleId: article.article_id,
          name: article.name,
          status: article.status as ArticleStatus | null,
          topImageM: article.top_img_m,
          sessionCount: article.session_count || 0,
          likeCount:
            article.like_count +
            article.article_page.reduce(
              (prev, curr) => prev + curr.like_count,
              0,
            ),
          commentCount: article.commentCount,
          discloseScope: article.disclose_scope as DiscloseScope | null,
          createDate: date.toIso8601Jst(article.create_date),
          updateDate: date.toIso8601Jst(article.update_date),
          author: {
            userId: article.user_article_header_author_idTouser.user_id,
            userName: idBaseProfile?.userName ?? null,
            // userNameが設定されている場合はfirstName, lastNameはフロントに返さないようにする
            lastName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.lastName ?? author.last_name,
            firstName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.firstName ?? author.first_name,
            updateDateEpochMilliseconds:
              article.user_article_header_author_idTouser.update_date.getTime(),
            profilePhotoS3Key:
              article.user_article_header_author_idTouser.profile_photo_s3_key,
            idBaseProfileUrl: idBaseProfile?.profilePhotoUrl ?? null,
          },
          tags: article.article_tag_connection.map((conn) => ({
            id: conn.tag.tag_id,
            name: conn.tag.tag_name,
          })),
        };
      }),
    );

    const weeklyRankingArticles = weeklyRankingArticlesFromDb.map((article) => {
      const author = article.user_article_header_author_idTouser;
      const idBaseProfile = idBaseProfileMap[author.disclose_id ?? ''];
      return {
        articleId: article.article_id,
        name: article.name,
        description: article.description,
        status: article.status as ArticleStatus | null,
        topImageM: article.top_img_m,
        sessionCount: article.session_count || 0,
        likeCount:
          article.like_count +
          article.article_page.reduce(
            (prev, curr) => prev + curr.like_count,
            0,
          ),
        commentCount: article.commentCount,
        discloseScope: article.disclose_scope as DiscloseScope | null,
        createDate: date.toIso8601Jst(article.create_date),
        updateDate: date.toIso8601Jst(article.update_date),
        author: {
          userId: article.user_article_header_author_idTouser.user_id,
          userName: idBaseProfile?.userName ?? null,
          // userNameが設定されている場合はfirstName, lastNameはフロントに返さないようにする
          lastName: idBaseProfile?.userName
            ? null
            : idBaseProfile?.lastName ??
              article.user_article_header_author_idTouser.last_name,
          firstName: idBaseProfile?.userName
            ? null
            : idBaseProfile?.firstName ??
              article.user_article_header_author_idTouser.first_name,
          updateDateEpochMilliseconds:
            article.user_article_header_author_idTouser.update_date.getTime(),
          profilePhotoS3Key:
            article.user_article_header_author_idTouser.profile_photo_s3_key,
          idBaseProfileUrl: idBaseProfile?.profilePhotoUrl ?? null,
        },
        tags: article.article_tag_connection.map((conn) => ({
          id: conn.tag.tag_id,
          name: conn.tag.tag_name,
        })),
      };
    });

    const [popularTags, popularAuthors] = await Promise.all([
      findPopularTags(),
      findPopularAuthors(),
    ]);

    let userViewedArticleCount = null;
    let userRecentlyViewedArticles = null;
    if (context.req.isAuthenticated()) {
      userViewedArticleCount = await countUserRecentlyViewedArticles(
        context.req.user.id,
      );
      userRecentlyViewedArticles = await findUserRecentlyViewedArticles(
        context.req.user.id,
      );
    }

    const userId = context.req.user?.id;
    const userAgent = context.req ? context.req.headers['user-agent'] : '';
    createUserAccessHistory(userId, userAgent, getGaCid(context.req));

    const isMobile = isMobileDevice(context.req.headers['user-agent']).any;

    return {
      props: {
        featuredArticles,
        newArticles,
        weeklyRankingArticles,
        popularTags,
        popularAuthors,
        userViewedArticleCount,
        userRecentlyViewedArticles,
        isMobile,
      },
    };
  },
);
