import React, { ReactNode } from 'react';
import { Container, Flex, Spacer, Box } from '@chakra-ui/react';
import SearchModal from '../components/SearchModal';
import DepartmentModal from '../components/DepartmentModal';
import AccountIntegrationContainer from '../components/AccountIntegrationContainer';
import AnnounceModal from '../components/AnnounceModal';
import NavBar from './NavBar';
import Footer from './Footer';
import ImportantNoticeModal from './ImportantNoticeModal';
import ProfileUpdateContainer from './ProfileUpdateContainer';

type Props = {
  children: ReactNode;
  sidebarContent: ReactNode;
};

export const layoutWithSidebarLgPaddingTop = '5rem';

function LayoutWithSidebar({ children, sidebarContent }: Props) {
  return (
    <Flex minH="100vh" direction="column" overflowX="hidden">
      <SearchModal />
      <AnnounceModal />
      <ImportantNoticeModal />
      <DepartmentModal />
      <AccountIntegrationContainer />
      {/* <ProfileUpdateContainer /> */}
      <NavBar />
      <Flex
        height={{
          base: 'initial',
          lg: '100vh',
        }}
        flexDirection={{
          base: 'column',
          lg: 'row',
        }}
      >
        <Box
          pt={[20, null, 20, layoutWithSidebarLgPaddingTop]}
          flex={1}
          overflowX={'hidden'}
          overflowY={'scroll'}
          id="mainContentContainer"
        >
          <Container pb={16}>{children}</Container>
          <Spacer />
          <Footer />
        </Box>
        <Box
          pt={{
            base: 0,
            lg: layoutWithSidebarLgPaddingTop, // flexDirection:rowで横並びになるlg以上の画面ではメインカラムのBoxと同じ値にする必要がある
          }}
          role={'aside'}
        >
          {sidebarContent}
        </Box>
      </Flex>
    </Flex>
  );
}

export default LayoutWithSidebar;
